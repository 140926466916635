import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { createGlobalStyle } from 'styled-components';
import BG from "./components/bg.svg";
import { BrowserRouter } from 'react-router-dom';


const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Gotham-Black";
    src: url("https://cpxfonts.cc/Gotham-Black.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Gotham-Light";
    src: url("https://cpxfonts.cc/Gotham-Ligth.otf") format("opentype");
  }

  @font-face {
    font-family: "Gotham-Medium";
    src: url("https://cpxfonts.cc/Gotham-Medium.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Gotham-Thin";
    src: url("https://cpxfonts.cc/Gotham-Thin.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Gotham-Ultra";
    src: url("https://cpxfonts.cc/Gotham-Ultra.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }


  html, body{
    margin: 0px;
    padding: 0px;
    overflow: hidden;
  }

  body{
    background-image: url(${BG});
    backgroud-size: cover;

  }
`

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <GlobalStyle />
    <App />
    </BrowserRouter>
  </React.StrictMode>
);
