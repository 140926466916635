import React from "react";
import "./style.css";
import espadilhaIcon from "../images/espadilha-icon.svg";
import popupImg from "../images/popup.png";
import logo from "../images/cassinopix-logo.svg";

interface ModalProps {
    token?: string;
}

const Modal: React.FC<ModalProps> = ({ token }) => {
    return (
        <div id="overlay">
            <iframe
                src="https://cassinopix.com/"
                style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    opacity: 0.7,
                    filter: "blur(4px)",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    zIndex: 1000,
                }}
            ></iframe>
            <div id="modal" style={{  zIndex: 1001 }}>
                <div className="top-modal">
                    <img id="poup-img" src={popupImg} alt="Popup" />
                </div>
                <div className="modal-content">
                    <a href={`https://rodadascpx.com/?_lp=1&_token=${token}`}>
                        <div className="container">
                            <img src={espadilhaIcon} alt="Espadilha Icon" />
                            10 rodadas grátis | Deposite R$10
                        </div>
                    </a>
                    <a href={`https://rodadascpx.com/?_lp=1&_token=${token}`}>
                        <div className="container">
                            <img src={espadilhaIcon} alt="Espadilha Icon" />
                            40 rodadas grátis | Deposite R$20
                        </div>
                    </a>
                    <a href={`https://rodadascpx.com/?_lp=1&_token=${token}`}>
                        <div className="container">
                            <img src={espadilhaIcon} alt="Espadilha Icon" />
                            100 rodadas grátis | Deposite R$50
                        </div>
                    </a>

                    <div className="btn">
                        <a href={`https://rodadascpx.com/?_lp=1&_token=${token}`}>DEPOSITAR</a>
                        <button onClick={() => window.location.href = `https://rodadascpx.com/?_lp=1&_token=${token}`}>Fechar</button>
                    </div>
                </div>
                <div>
                    <img id="logo" src={logo} alt="CassinoPix Logo" />
                </div>
            </div>
        </div>
    );
};

export default Modal;
